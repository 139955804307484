<template>
	<div class="">
		<div>
			<el-carousel height="400px" v-if="bannerList[0]">
				<el-carousel-item v-for="(item,itemIndex) in bannerList" :key="itemIndex">
					<el-link :underline='false' :href="item.fieldB" target="view_window">
						<el-image style="width: 100%;" :src="item.fieldA"></el-image>
					</el-link>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="page-content top">
			<div>
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>学术会议</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="top">
				<el-card class="box-card">
					<div style="display: flex;">
						<div style="width: 8%;">
							<p style="font-weight: normal;color: black;">会议类型</p>
						</div>
						<div>
							<div class="metting-card">
								<div v-for="(item,itemIntex) in meetingType" :key="itemIntex"
									style="margin-right: 10px;margin-top: 10px;">
									<el-check-tag effect="plain" type="" @click="tagCheck(itemIntex)"
										:class="itemIntex == tagIndex ? 'tagActive' : ''">{{item}}</el-check-tag>
								</div>
							</div>
						</div>
					</div>
				</el-card>
			</div>
			<div class="top metting-card card-b meetingLoading">
				<el-card shadow="hover" class="box-card card-c" :body-style="{ padding: '0px' }"
					v-for="(item,index) in meetintFilterList" :key="index" @click="meetingDetails(item.fieldB)">
					<el-image style="width: 280px;height: 140px;" :src="item.fieldA"></el-image>
					<div style="padding: 14px;">
						<div class="two">
							{{item.fieldC}}
							<!-- <el-link :href="item.fieldB">{{item.fieldC}}</el-link> -->
							<el-image :src="require('@/assets/images/hot.gif')" class="index-meeting"
								v-if="item.fieldG=='火'">
							</el-image>
						</div>
						<div class="two">
							<i class="el-icon-time" style="margin-right: 5px;"></i>
							<span>{{item.fieldD}}</span>
						</div>
						<div class="two">
							<i class="el-icon-location-outline" style="margin-right: 5px;"></i>
							<span>{{item.fieldE}}</span>
						</div>
					</div>
				</el-card>
			</div>
		</div>
	</div>
</template>
<script>
	import $http from "@/request/http";
	export default {
		data() {
			return {
				meetintList: [],
				meetingType: [],
				tagIndex: 0,
				tagIndex2: 0,
				checked: false,
				meetingSearch: "",
				meetintFilterList: [],
				bannerList: [],
			};
		},
		methods: {
			tagCheck(itemIntex) {
				this.tagIndex = itemIntex;
				this.createFilter();
			},
			tagCheck2(itemIntex) {
				this.tagIndex2 = itemIntex;
				this.createFilter();
			},
			search() {
				this.createFilter();
			},
			meetingDetails(index) {
				window.open(index);
			},
			queryIamsetOtherData2() {
				let _this = this
				let paramMap = {
					page: 1,
					pageSize: 20,
					classification: "meeting"
				};
				$http
					.post("queryIamsetOtherData", paramMap, "meetingLoading")
					.then(function(res) {
						_this.meetintList = res.data;
						_this.meetintFilterList = res.data;

					})
					.catch(function() {});
			},
			queryIamsetOtherData3() {
				let _this = this
				let paramMap = {
					page: 1,
					pageSize: 20,
					classification: "meetingBanner"
				};
				$http
					.post("queryIamsetOtherData", paramMap)
					.then(function(res) {
						_this.bannerList = res.data;
					})
					.catch(function() {});
			},
			queryIamsetOtherData1() {
				let _this = this
				$http
					.post("queryIamsetMeetingType")
					.then(function(res) {
						_this.meetingType = res.data.map(item => (item.fieldF))
						_this.meetingType.splice(0, 0, '全部');

					})
					.catch(function() {});
			},

			createFilter() {
				let _this = this
				let nowDate = new Date()
				let filter1 = new Array();
				let filter2 = new Array();
				let filter3 = new Array();
				if (_this.tagIndex != 0) {
					this.meetintList.filter(function(item) {
						if (item.fieldF == _this.meetingType[_this.tagIndex]) {
							filter1.push(item)
						}
					})
				} else {
					filter1 = _this.meetintList
				}

				// if (_this.tagIndex2 != 0) {
				// 	if (_this.tagIndex2 == 1) {
				// 		var reg = RegExp(nowDate.getMonth() + 1 + "月")
				// 		filter1.filter(function(item) {
				// 			if (reg.test(item.Time)) {
				// 				filter2.push(item)
				// 			}
				// 		})
				// 	} else if (_this.tagIndex2 == 2) {
				// 		var reg = RegExp(nowDate.getFullYear())
				// 		filter1.filter(function(item) {
				// 			if (reg.test(item.Time)) {
				// 				filter2.push(item)
				// 			}
				// 		})
				// 	}
				// } else {
				// 	filter2 = filter1
				// }

				// var reg = RegExp(_this.meetingSearch)
				// filter2.filter(function(item) {
				// 	if (reg.test(item.fieldF)) {
				// 		filter3.push(item)
				// 	}
				// })
				_this.meetintFilterList = []
				_this.meetintFilterList = filter1
			},
		},

		created() {
			this.queryIamsetOtherData3()
			this.queryIamsetOtherData2()
			this.queryIamsetOtherData1()
		},
		mounted() {

		},
	};
</script>

<style lang="scss" scoped>
	.tagActive {
		background-color: #375691;
		color: white;
	}

	.metting-card {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 20px;
	}

	.card-b {
		.el-card {
			width: 280px;
		}

		.card-c {
			margin-top: 20px;
			margin-right: 35px;
		}

		// .card-c:not(:nth-child(5n)) {
		// 	margin-right: calc(11% / 4);
		// }
	}
</style>